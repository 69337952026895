import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import scrollToElement from "scroll-to-element";
import PropTypes from "prop-types";

import { Button, SafeImage, Layout } from "../components/common";
import { processHtml } from "../utils/processHtml";
import { canonicalize } from "../utils/canonicalize";

import HeaderIllu from "../assets/illustrations/pages/job.svg";

const Container = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  > div {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
    > h2 {
      font-size: 2rem;
      line-height: 2.5rem;
      margin: 3.5rem 0 2rem 0;
      max-width: 100%;
      :first-of-type {
        margin-top: 0 !important;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin: 1.5rem auto 0 auto;
    width: 100%;
    @media ${props => props.theme.device.tablet} {
      width: 50%;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10rem;
    margin-bottom: 0.5rem;
    @media ${props => props.theme.device.tablet} {
      height: 20rem;
      margin-bottom: 2%;
    }
  }
  .image {
    object-fit: cover;
    object-position: 50% 50%;
  }
  .white-area {
    h2 {
      margin-top: 0 !important;
    }
  }
`;

const Job = ({ data }) => {
  const { html, plaintext } = data.ghostPage;
  const { htmlX, jumbotronData } = processHtml(html, plaintext);

  return (
    <Layout
      title={data.ghostPage.title}
      subtitle={jumbotronData.subtitle}
      meta={{
        title: data.ghostPage.meta_title,
        description: data.ghostPage.meta_description,
        canonical: canonicalize(data.ghostPage.url, "we-are-hiring"),
        featureImage: data.ghostPage.feature_image
      }}
      jumbotronVariant="jobs"
      illustrations={
        <img className="smaller-illustration" alt="job" src={HeaderIllu} />
      }
    >
      <Container className="container">
        <div className="content-gray full-width-container">{htmlX[0]}</div>
        <div className="white-area">
          {htmlX[1]}
          <Button
            onClick={() => scrollToElement(`#form`, { offset: -100 })}
            className="button"
          >
            Apply
          </Button>
        </div>
        <div className="full-width content-gray">
          <div className="row">
            <SafeImage
              className="image"
              alt="team building image 1"
              style={{ width: "32%" }}
              src="team-building/01.jpg"
            />
            <SafeImage
              className="image"
              alt="team building image 2"
              style={{ width: "66%" }}
              src="team-building/02.jpg"
            />
          </div>
          <div className="row">
            <SafeImage
              className="image"
              alt="team building image 3"
              style={{ width: "66%" }}
              src="team-building/03.jpg"
            />
            <SafeImage
              className="image"
              alt="team building image 4"
              style={{ width: "32%" }}
              src="team-building/04.jpg"
            />
          </div>
          <div className="row">
            <SafeImage
              className="image"
              alt="team building image 5"
              style={{ width: "32%" }}
              src="team-building/05.jpg"
            />
            <SafeImage
              className="image"
              alt="team building image 6"
              style={{ width: "32%" }}
              src="team-building/06.jpg"
            />
            <SafeImage
              className="image"
              alt="team building image 7"
              style={{ width: "32%" }}
              src="team-building/07.jpg"
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Job;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      html
      plaintext
      title
      meta_title
      meta_description
      url
      feature_image
    }
  }
`;
